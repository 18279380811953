
body{
	background: #f6f6f6;
	margin:0;
	font-size:12px;
	color:#555;
}

.bdr{
	border:1px solid red
}


#wrapper{
	position:relative; 
	/*z-index:10;*/
	background: #f6f6f6;
	box-shadow: 0px 0px 10px #cfcfcf;
}
	

#logo,#osbi_logo,.arrow{
	background-repeat:no-repeat;
}


.col-comn,.nav-comn{
	padding:0;
}



header{
	background:#fff;
	/* overflow:hidden; */
	padding:10px 0;
}

#logo{
	background-position: -278px -122px;
	width:100px;
	height:54px;
	display:block;
}

#osbi_logo{
	background-position: -178px -9px;
	width:73px;
	height:50px;
	float:right;
}

#logo:hover,#osbi_logo:hover,.arrow:hover{
	text-decoration:none;
}

#header_navigation{
	/* overflow:hidden; */
	float:right;
	margin:17px 20px 0 0;
}

#forms, #header_links{
	padding:0;
	margin:0;
	/* overflow:hidden; */
	list-style:none;
}
#homeLoanButton{ margin-top:-8px !important;}

@media (min-width: 320px) and (max-width: 767px){
	#homeLoanButton{
		border-right:none !important;;
		width:100%
	}
		
		#homeLoanButton a{
		float:right;
		padding-bottom: 5px;
	}
	}

#forms li{
	float:left;
	margin:0 10px 0 0;
	border-right:1px solid #c1c1c1;
}

#forms li:last-child{
	border-right:0;
	margin-right:0;
}

#forms li a{
	display:block;
	color:#888;
	margin:0 10px 0 0;
}

#forms li a.last{
	margin-right:0;
}

#primary_navigation .navbar-default{
	border:0;
	background:#1b75bc;
	box-shadow:inset 0 3px 4px #1764a0;
}

#primary_navigation .navbar{
	border-radius:0;
	margin:0;
	min-height:auto;
}

#navigation {
	list-style:none;
	padding:0;
	margin:0;	
}

#navigation li{
	float:left;
	padding:0;
}

#navigation li a{
	color:#fff;
    display:block;
    font-size:13px;
	font-weight:bold;
    text-align:center;
    text-decoration:none;
	padding:12px 26px;
}

#navigation li a.active, #navigation li a:hover{
	background-color:#14b2e2;
	box-shadow:inset 0 8px 10px -8px #1764a0;
}

#navigation li.navbar-right{
	margin-right:0;
}

#navigation:after{
	content:"";
	display:block;
	clear:both;
	visibility:hidden;
	line-height:0;
	height:0;
}

#primary_navigation .navbar-default .navbar-nav > .active > a{
	background-color:#1b75bc;
}

#primary_navigation .navbar-default .navbar-nav > .open > a, #primary_navigation .navbar-default .navbar-nav > .open > a:hover, #primary_navigation .navbar-default .navbar-nav > .open > a:focus{
	background-color:#f3f3f3;
	color:#555;
	box-shadow:none;
}

.banner_box
{
	margin:15px 0px;
	border:1px solid #a8dcf1;
}

#banner{
	position:relative;
	z-index:10;
}

#banner img{
	width:100%;
}

.continue_btn{
    bottom: 84px;
    position: absolute;
    right: 17px;
}

.continue_btn a{
	background:#2b9dcc;
    color:#fff;
    display:block;
    font-size:15px;
	font-weight:bold;
    padding:10px;
    text-align:center;
    width:250px;
}

.continue_btn a:hover{
	color:#c1ecff;
	background:#007fb4;
	text-decoration:none;
}

#phishing_message{
	border:1px solid #dcdcdc;
	padding:15px 0;
}

.phishing_box{
	background: #fff;
    border: 1px solid #dcdcdc;
    position: relative;
}

.accept, .reject {
    display: inline-block;
    height: 45px;
    left: 0px;
    position: absolute;
    top: 0;
    width: 100px;
}



.phishing_box h3{
	background:#e5e5e5;
	padding:13px 0;
	margin:0 0 0 20px;
	font-size:16px;
	text-align:center;
	font-weight:normal;
}

.never{
	color:#bf1918;
}

.always{
	color:#098809;
}

.phishing_box p{
	text-align:center;
	color:#444;
	margin:20px 0;
}

.own_head::after{
	background: #1b75bc;
    content: "";
    display: block;
    height: 2px;
    margin: 10px auto 0;
    width: 60px;
}

.own_head{
	margin:10px 0;
	color:#1b75bc;
	font-size:18px;
	text-align:center;
	margin:15px 0;
	padding:0;
}

#phishing_contents{
	background:#fff;
	padding:12px 0;
}

#phishing_contents h3{
	background:#e6e6e6;
	color:#555;
	padding:7px;
	margin:0;
	font-size:13px;
	font-weight:bold;
}

.phishing_list{
	margin:0 0 0 5px;
	padding:0;
	list-style:none;
}


.phishing_list li a{
	color:#189bd1;
}

#terms_area{
	padding:12px 0;
}

.terms{
    background:#e9f0f6;
    color:#34495e;
	border:1px solid #bdc8d4;
	display: flex;
	align-items: center;
}

.terms p{
	padding:10px 0;
	margin:0;
}

.cont_btn_bottom{
	background-color: #2b9dcc;
	text-align: center;
	padding:3px 0px;
}

.cont_btn_bottom:hover{
	background-color: #007fb4;
}
.terms a {
    color: #fff;
    display: inline;
    font-size: 14px;
}

.terms a:hover{
	color:#c1ecff;
	text-decoration: none;
}

.login_heading {
    background: #e8f3f9;
    border-top: 3px solid #dde6eb;
	overflow:hidden;
    /*margin: 12px 0 0;*/
	color:#124c8c;
}

.login_heading h3{
	padding:0;
	margin:10px 0;
	font-size:13px;
	font-weight:bold;
}


#login_fields{
	background:#fff;
	overflow:hidden;
}

.user_details{
	border-right:1px solid #e3e3e3;
	padding-right:0;
}

.user_details p{
	margin:10px 0;
}

.user_details span {
    color:#c33a1a;
    font-weight:bold;
}

#login_fields .form-group{
	overflow:hidden;
	margin-bottom:12px;
}

.form-fields { 
	margin:15px 0px;
}
.info-txt {
	line-height: 30px;
}
#login_fields .form-group label{
	display:block;
	padding:5px 0px 0px 10px;
}
.common_btn .btn {
	background: #f89611 none repeat scroll 0 0;
    color: #fff;
    padding: 3px 10px;
	border:none;
}
#login_fields .form-control{
	background:#f1f1f1;
    border:1px solid #ccc;
    padding:5px;
	border-radius:0;
	box-shadow:none;
	height:auto;
}

.user_details .btn {
    color:#fff;
    padding:5px 13px;
	margin:0 5px 0 0;
}
.user_details .btn-default {
    background:#486d90;
    border:0 none;
	border-radius:0;
}

.user_links {
	list-style:none;
	margin:10px 0 0;
	padding:0;
	overflow:hidden;
}

.user_links li{
	margin:17px 0;
}

.user_links li a{
	display:block;
}

.forgot_details{
	padding-right:0;
}


.virtual p{
	margin:15px 0 0 50px;
}

.virtual_link{
	background:#f2f6f7;
	padding:10px;
	clear:both;	
}

.virtual_link p{
	margin:0;
}


#login_container{
	margin:15px 0;
	overflow:hidden;
	background:#F3F6F7;
}

#login_area{
	background:#fff;
}

.login_box{
	background:#fafafa;
    border:1px solid #eee;
	margin:15px 0;
    padding:20px 30px;
    text-align:center;
	min-height:237px;
}

.login_box p{
	line-height:26px;
	margin:10px 0 0;
	text-align:justify;
}

.login_box:hover {
    box-shadow: 0 0 8px #eee;
}

.login_box_links{
	list-style:none;
	margin:10px 25px 0 0;
	padding:0;
	overflow:hidden;
	float:left;
}

.login_box_links li {
	margin:3px 0;
	padding:5px 0 0;
	border-bottom:1px dotted #cecece;
}

.login_box_links li:last-child {
    padding-top: 0;
	border-bottom:0;
}

.login_box_links li a{
	display:block;
    padding:0 0 5px;
}

.login_box .glyphicon{
	font-size:32px;
	color:#1B75BC;
	display:block;
}

#provide_list{
	background:#e2ecf0;
}

.provide{
	list-style:square;
	margin:0 0 0 20px;
	padding:0;
}

.provide li{
	margin:7px 0;
	padding:0 0 0 10px;
}

/*#login_block {
    display:none;
}*/


/* Virtual Key Board Starts */
.vkb_link {padding:5px; color:#2A6097; font:100% Arial, Helvetica, sans-serif; border-top:1px dashed #ccc}
.vkb_link a{color:#2A6097; text-decoration:none;}
.vkb_link a:hover{text-decoration:underline;}
.kbd_heading{font:14px Arial, Helvetica, sans-serif; padding:5px 0px 0px 0px; margin:0px; font-weight:bold; text-align: center; }
.keyboardtbl {font-family: Arial, Helvetica, sans-serif; margin:0px; padding:0px;border-collapse: separate !important;border-spacing: 0;}
.keyboardtblenb {padding:9px; background:#F0F5F7;text-align:center; cursor:pointer; font-weight:bold; vertical-align:middle; border:1px solid #377292;font-size: 12px; }
.keyboardtbldis {padding:3px 8px 3px 8px; background-color:#edf2f2;text-align:center; vertical-align:middle; border:1px solid #d9d8d8; color:#929290; font-weight:bold}
.vkb_login_heading{ font:100% Arial, Helvetica, sans-serif;}
.vkb_login_heading span{color:#407CB9; font-size: 14px; font-weight:bolder; margin:0px; padding:0px}
.vkb_tbl{font:80% Arial, Helvetica, sans-serif; vertical-align:top; margin:0px; padding:2px}
.vkb_tbl td{padding:4px 2px 2px 2px;}
#vkb_content{color:#36505E;}
.vkb_aligntop{vertical-align:top; padding:4px 6px 0px 5px;}
.vkb_loginsublinks{color:#003399;font:80% Arial,Helvetica,sans-serif;padding-bottom:6px}
.vkb_loginsublinks a{text-decoration:underline}
.vkb_errormsg{color:red;font-weight:bold}
.keyboardtbl td {margin:0px 5px;}

.login_cred input {background:#ededed;border:1px solid #cccccc; width:220px;padding: 5px;}

.keyboardtb_new {margin:0 0 0 15px; }
.keyboardtb_new  td {border-spacing:7px;}
.keyboardtb_new td.key_head {font-size:16px;text-align:center;padding:10px 0px 10px 0px;font-weight:bold}
.spl_txta {color:#c33a1a; font-weight:bold;}
.kbplaceholder {margin:5px 0 0;}
#cap, #clr {font-size:11px!important}
/* Virtual Key Board Ends */

/*----- Footer Section Starts ----- */

#footer_area {
    background: #414141;
    border-top: 3px solid #1b75bc;
    overflow: hidden;
    padding: 5px;
}
#footer p {
    color: #fff;
    margin: 10px 0 0;
}

.footbg{
	background:#535353;
	overflow:hidden;
}

#footer_links {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
}
#footer_links li {
    float: left;
    margin: 0 10px 0 0;
    padding: 5px 0;
    
}
#footer_links li:last-child {
    margin-right: 0;
    background: none;
}
#footer_links li a {
    color: #fff;
    padding: 0 10px 0 0;
    margin: 3px 0 0;
    display: block;
}
#footer_links li a:hover {
    text-decoration: none;
}
#best_view {
    background: #535353;
    text-align: center;
}
#best_view p {
    font-size: 11px;
    margin: 0;
    padding: 5px;
    color: #eee;
}
/*----- Footer Section Ends -----*/

	
@media (min-width: 768px) and (max-width: 991px){

#navigation li a{
	padding:10px 22px;
}

.continue_btn {
    bottom:45px;
    right:6px;
}

.continue_btn a {
    font-size:13px;
    padding:7px;
    width:175px;
}

#wpanel_vticker ul li{
	
	height:50px !important;
	line-height:20px !important;
	
}

.login_heading span {
background:none;
padding:13px 0px 0px;

}

.login_heading h3 {
margin:15px 0px;
}
#wpanel_vticker {
height:50px !important;
}

.phishing_box p {
    font-size:11px;
    margin:15px 0;
}

.phishing_box h3 {
    font-size:14px;
    margin:0 0 0 32px;
    padding:14px 0;
}

#phishing_contents h3{
	font-size:12px;
}

#navigation li a {
    padding:8px 16px;
}

.login_heading h3{
	font-size:12px;
}

.user_details p ,#login_fields .form-group label,.user_links li a,.user_details .btn,.virtual p,.virtual_link p,.login_box p,.login_box_links li a,.provide li,footer p,#footer_links li a,#forms li a {
    font-size:11px;
}


.virtual p {
    margin:15px 0 0 22px;
}

.login_box .glyphicon{
	font-size:26px;
}

.login_box {
    margin:10px 0;
    min-height:206px;
    padding:12px 5px;
}

.login_box p{
	line-height:18px;
}

.login_box_links {
    margin: 10px 2px 0 0;
}

.provide li{
	padding:0 0 0 5px;
}

.keyboardtb_new td.key_head {
    font-size:12px;
    padding:5px 0;
}

.keyboardtb_new {
    margin:0 0 0 10px;
}

#keypad img{
	height:247px;
}
		
}

@media (min-width: 992px) and (max-width: 1199px){

.continue_btn {
    bottom:25px;
    right:50px;
}

.login_box p{
	line-height:20px;
}

.login_box_links {
    margin: 10px 10px 0 0;
}

.login_box {
    min-height:230px;
    padding:12px 15px;
}



}
		
@media (min-width: 320px) and (max-width: 767px){
	.err_mssg{
		margin:0px !important;
		
	}
	.terms
	{
		display: block;
	}
#headerContent span#logo
{
	margin:0px auto;
}
#logo{
	background-position: -168px -17px;
	width:90px;
	height:45px;
}

#wpanel_vticker {
	height:45px !important;
}

#wpanel_vticker ul {
	width:100% !important;
}
#wpanel_vticker ul li {
    height: 38px !important;
	line-height:16px !important;
}
.login_heading {
    text-align: center;
}
#osbi_logo{
	background-position: -227px -92px;
	width:59px;
	height:40px;
}

#header_navigation{
	margin:10px 10px 0 0;
}

#forms li{
	margin:0 5px 0 0;
}

#forms li a{
	margin:0 5px 0 0;
	font-size:11px;
}

#primary_navigation .navbar-default .navbar-toggle .icon-bar{
	background-color:#fff;
}

#primary_navigation .navbar-default .navbar-toggle:hover, #primary_navigation .navbar-default .navbar-toggle:focus{
	background:none;
}

#primary_navigation .navbar-toggle{
	float:left;
	margin-left:10px;
	border-radius:0;
}

#primary_navigation .navbar-default .navbar-toggle{
	border:0;
}

#navigation li{
	float:none;
}

#navigation li a{
	text-align:left;
	padding:9px 18px;
}

.arrow_icon{
	background:#005292;
	padding:13px 12px;
}

.arrow:hover,.arrow:focus{
	outline:none;
}

.arrow{
	width:23px;
	height:23px;
	display:block;
	text-decoration:none;
	outline:none;
}

.arrow_up{
	background-position: -327px -15px;
}

.arrow_down{
	background-position: -326px -67px;
}

#header_navigation{
	display:none;
}

.continue_btn{
	position:static;
	padding: 10px 10px 10px 24px;
}

.phishing_box{
	margin:0 0 12px;
}

.last_box{
	margin:0 !important;
}

.user_links li {
    margin:10px 0;
}

.virtual p {
    position:absolute;
    right:60px;
}

#keypad img{
	width:100%;
}

.login_box_links {
    margin: 10px 10px 0 0;
}

.login_box_links li a,.login_box p,.provide li,footer p{
	font-size:11px;
}

.login_box {
    min-height:230px;
}

.login_box p{
	line-height:22px;
}

.keyboardtblenb{
	padding:6px 7px;
}

.keyboardtb_new td.key_head {
    font-size:13px;
}

.login_xs{
	width: 250px;
	margin: 0 auto;
}

}
/*Old browsers alert Box starts*/

.overlay {
    width: 100%;
    height: 722px;
    background: #999999;
    z-index: 10000;
    opacity: 0.6;
    filter: alpha(opacity=60);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    top: 0px;
    left: 0;
    position: fixed;
    display: none;
    overflow: hidden;
}
.ie6_alertbox {
    z-index: 100001;
    background: #fff;
    top: 170px;
    /*right:0px;
	margin-right: -741px;*/
    
    left: 30%;
    right: 30%;
    width: 40%;
    position: absolute;
    display: none;
    border-radius: 7px;
    box-shadow: 5px 5px 5px #888888;
}
.alertbox_icon_container {
    padding: 20px 10px;
    width: 100%;
    float: left;
}
.alertbox_logo {
    
    width: 100%;
    height: 55px;
    display: inline-block;
}
.alertbox_icon {
    
    width: 100%;
    height: 106px;
    margin: 45px 0 0 40px;
    display: inline-block;
}
.alertbox_content {
    width: 100%;
    padding: 10px;
    float: left;
}
.alertbox_content p {
    font-size: 12px;
}
.alertbox_close {
    
    width: 68px;
    height: 28px;
    margin-top: -35px;
    float: right;
}
.link_for_ie {
    color: #027f9f;
    padding: 10px 0 10px 0;
    display: inline-block;
}
.square_list {
    list-style-type: square;
}
.browsers li {
    float: left;
    margin-right: 25px;
    list-style-type: none;
}

.modal-header,.modal-body{
	padding:5px 15px;
}
/*Old browsers alert Box ends*/	

#wpanel_vticker ul{
	width:90%;
	text-align: center;
	list-style-type: none;
}
#wpanel_vticker ul li{
	box-sizing: border-box;
    color: #DB3C38;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
	font-size: 13px;
	line-height: 31px;
	height:31px;
	
}
#wpanel_vticker ul li a
{
	color: #198379;
}
.vertical_center {
	display: flex;
	align-items: center;
}
.net_branch .modal-dialog{
	margin:10% auto;
}
.net_branch .modal-header{
	padding:5px 15px 0px 15px;
}
.login_heading span.newIcon {
	
	height:20px !important;
	margin:5px 0px 0px;
		
}
#wpanel_vticker {
padding:0px 0px 10px 0px;
}
.err_mssg{
	display:none;
	position: absolute;
	margin-top:10px;
	font-weight:bold;
	text-align:center;
	background: #fff7e7;
	
	border-radius:5px;
	padding:5px;
	color:red;
	left:17%;
	z-index:1;
	border:solid 1px #dbd0ba;
	max-width:72%;
}
.care_msg{
	padding:0px;
}
#dummy {
		display: none;
	}
	#features{
		    
			bottom: 0;
			color: #0071a5;
			height: 40px;
			padding: 15px 0 15px 78px;
			position: absolute;
			right: 0;
			width: 155px;
	}


