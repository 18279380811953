body {
    margin: 0; /* Remove default body margin */
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); /* Adjust the background color and opacity as needed */
    z-index: 1000;
    backdrop-filter: blur(5px); /* This creates the blur effect */
}

.loading-circle-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
}

.loading-circle {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.container {
    max-width: 100%;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.banner_box {
    margin-top: 20px;
    text-align: center;
}

.continue_btn {
    text-align: center;
    margin-top: 20px;
    position: relative;
}

.login_button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 2px solid transparent;
    border-radius: 4px;
    background-color: #10578d;
    color: #ffffff;
    max-width: 100%;
    display: inline-block;
}

.login_button:hover {
    background-color: #0d3b66;
}

#phishing_block {
    margin-top: 20px;
    position: relative;
}

.footer-img {
    margin-top: 20px;
}

.ticker-msg {
    max-height: 70px;
    overflow: hidden;
}
