body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.top-header {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
  font-size: 24px;
}

.data-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.data-container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.data-container th,
.data-container td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.data-container tr:nth-child(even) {
  background-color: #f2f2f2;
}

.data-container tr:hover {
  background-color: #ddd;
}

.data-container th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #333;
  color: white;
}
